require('./frontend.scss');
require('./frontend-resources.js');
require('slick-carousel');

const $ = (window.jQuery = require('jquery'));
const Masonry = require('masonry-layout');
const MultiLoader = require('./multi-loader');
const { autoRecaptcha } = require('./recaptcha');
const ajaxForm = require('./ajax-form');

function returnToTop(sel) {
	$(sel).click(function () {
		$('html, body').animate({ scrollTop: 0 }, 500);
	});
}

function fancyLabels(sel) {
	$(sel).focus(function () {
		$(this).parent().addClass('move-label');
	});

	$(sel).blur(function () {
		if (!$(this).val()) {
			$(this).parent().removeClass('move-label');
		}
	});
}

function resetServiceDropdowns() {
	$('.services-popout, .boxes-services .box').removeClass('active');
}

function serviceDropdown(sel) {
	$(sel).click(function () {
		var boxToTrigger = $(this).data('service');
		if ($(this).hasClass('active')) {
			$(this).removeClass('active');
			$(boxToTrigger).removeClass('active');
		} else {
			resetServiceDropdowns();
			$(boxToTrigger).addClass('active');
			$(this).addClass('active');
			$('html, body').animate({
				scrollTop: $(boxToTrigger).offset().top - 100
			}, 'slow')
		}
	});
}

function triggerTestimonial(sel) {
	$(sel).click(function () {
		if ($(this).parent().hasClass('active')) {
			$(this).parent().toggleClass('active');
			$('.clients-testimonials').removeClass('active');
		} else {
			$('.clients-testimonials').addClass('active');
			$('.testimonial').removeClass('active');
			$(this).parent().addClass('active');
		}
	});
}

function menuTrigger(sel){
	$(sel).click(function(){
		$(this).toggleClass('active');
		$('nav .links').toggleClass('active');
		$('body').toggleClass('menu-active');
	});
}

$('.js-toggle-team').click(function(){
    var bio = $(this).data('bio');
    $('.team .image').removeClass('active');
    $('.team .bio').removeClass('active');

    $(this).addClass('active');
    $('#' + bio).addClass('active');
    $('html, body').animate({
        scrollTop: eval($('#' + bio).offset().top - 20)
    }, 2000);

});


const codePerPage = {
	contact() {
		const leftheight = $('.contact-form .left').height();
		$('.contact-form .right textarea').css('height', leftheight);
	},

	blog() {
		const masonry = new Masonry('.blog-items .entries', {
			itemSelector: '.blog-items .entry',
			percentPosition: true,
		});

		const $cat = $('#postCategory');

		const ml = new MultiLoader({
			url(page) {
				const cat = $cat.val();
				var url = `/api/blog?p=${page}`;
				if (cat) url += `&cat=${cat}`;
				return url;
			},
			container: '#postList',
			more: '#postMore',
			template: '#postTemplate',
			masonry,
		});

		$cat.change(() => {
			ml.clear().fetch();
		});
	},
};

function gaTriggers() {
	gtag('event', 'click', {
		event_category: $(this).data('ga-category'),
		event_label: $(this).data('ga-label'),
	});
}

$(function () {
	returnToTop('.return-top');
	serviceDropdown('.boxes-services .box');
	fancyLabels('input, textarea');
	$('.js-close-service').click(resetServiceDropdowns);
	menuTrigger('nav .menu');
	$('.js-track').click(gaTriggers);

	$('.clients-testimonials').slick({
		slidesToShow: 2,
		prevArrow: $('.previous'),
		nextArrow: $('.next'),
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
			  }
			},
		]
	});

	const url = top.location.pathname.substr(1);
	if (codePerPage[url]) codePerPage[url]();

	triggerTestimonial('.testimonial .image');

	var noHashURL = window.location.href.replace(/#.*$/, '');
	window.history.replaceState('', document.title, noHashURL);

	autoRecaptcha(
		'.recaptcha-field',
		'6LcADfoUAAAAAEfyJxf2AmAhjYtOIyXyYC7Oqyqy',
		url
	);

	ajaxForm('form.ajax');
});
