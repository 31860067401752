const $ = (window.jQuery = require('jquery'));
require('jsrender');
const Masonry = require('masonry-layout');

/**
 * Make a multipage loader thingy.
 *
 * @param {string|function} url API url
 * @param {string} container container element ID
 * @param {string} more more element ID
 * @param {string} template template element ID
 * @param {Masonry} mason masonry instance
 */
class MultiLoader {
	constructor({ url, container, more, template, masonry }) {
		this.$container = $(container);
		this.$more = $(more);
		this.masonry = masonry;
		this.template = $.templates(template);

		if (typeof url === 'function') this.urlBuilder = url;
		else this.url = url;

		this.add = this.add.bind(this);
		this.fetch = this.fetch.bind(this);
		this.render = this.render.bind(this);

		this.clear();
		this.fetch();

		this.$more.click(this.fetch);
	}

	/**
	 * Get API url to hit next.
	 *
	 * @returns {string}
	 */
	getUrl() {
		const { page, url, urlBuilder } = this;

		if (url) return `${url}?p=${page}`;
		return urlBuilder(page);
	}

	/**
	 * Clear all items.
	 *
	 * @returns {this} this
	 */
	clear() {
		this.masonry.remove(this.$container.children());
		this.$container.empty();
		this.page = 1;

		return this;
	}

	/**
	 * Fetch new items from the API.
	 *
	 * @returns {this} this
	 */
	fetch() {
		$.getJSON(this.getUrl(), data => {
			this.page++;
			this.add(data.items);

			if (data.more) this.$more.show();
			else this.$more.hide();
		});

		return this;
	}

	/**
	 * Add items to the container.
	 *
	 * @param {array} data API data
	 * @returns {this} this
	 */
	add(data) {
		const news = $(data.map(this.render).join(''));
		this.$container.append(news);
		this.masonry.appended(news);
		this.masonry.layout();

		return this;
	}

	/**
	 * Render a single entry.
	 *
	 * @param {array} entry API data
	 * @returns {string}
	 */
	render(entry) {
		return this.template.render({
			...(window.jsTemplateData || {}),
			...entry,
		});
	}
}

module.exports = MultiLoader;
