const $ = (window.jQuery = require('jquery'));

var $recaptchaFields = $();
var recaptchaKey = '';
var recaptchaAction = '';

function refreshRecaptcha() {
	grecaptcha.execute(recaptchaKey, { action: recaptchaAction }).then(token => {
		$recaptchaFields.val(token);
	});
}

function autoRecaptcha(field, key, action) {
	$recaptchaFields = $(field);
	if (!$recaptchaFields.length) return;

	recaptchaKey = key;
	recaptchaAction = action;

	const script = document.createElement('script');
	script.src = `https://www.google.com/recaptcha/api.js?render=${key}`;
	script.onload = () => {
		grecaptcha.ready(refreshRecaptcha);
	};

	document.body.appendChild(script);
}

module.exports = { autoRecaptcha, refreshRecaptcha };
